<template>
  <div class="join-us">
    <div class="left">
      <div class="city-select-area">
        <div class="city-all">
          <div class="city-title">城市选择：</div>
          <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选
          </el-checkbox>
        </div>
        <div class="city-body">
          <el-checkbox-group text-color="#000" fill="green" v-model="checkedCities" @change="handleCheckedCitiesChange">
            <el-checkbox text-color="#000" fill="green" v-for="(city, i) in cities" :checked="cities[i].checked"
              :label="city.value" :key="i" @change="changeItem(i)">{{ city.label }}</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="join-list">
        <div class="join-item" v-for="(item, i) in list" :key="i">
          <div class="item-content" @click="item.showMore = !item.showMore">
            <div class="con-head">
              <div class="con-title">{{ item.title }}</div>
              <div class="con-adderss">
                工作地点：{{ item.workCityDic_dictText }}
              </div>
            </div>
            <div class="con-text" :class="{ 'notShowMore': item.showMore }" v-html="item.recruitContent">
            </div>
          </div>
          <div class="item-sub-btn">
            <span @click="dialogVisible = true" class="sub-btn">投递简历</span>
            <!-- <div class="sub-btn">
              投递简历
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="简历投递地址" :visible.sync="dialogVisible" width="30%">
      <div>邮件：gongxiaoran@gtcom.com.cn</div>
      <div>电话：15313113181</div>
      <span slot="footer" class="dialog-footer">
        <el-button type="info" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getDicItemByCode, recruitmentList } from '../api';
// const cityOptions = ['北京', '青岛', '杭州', '厦门'];
export default {
  data() {
    return {
      checkAll: true,
      checkedCities: [],
      cities: [],
      isIndeterminate: true,
      cityOptions: [],
      pageNo: 1,
      total: 0,
      list: [],

      dialogVisible: false,
    };
  },
  watch: {},
  methods: {
    // 页面数据初始化
    init() {
      // 获取城市类表
      this.getDicItemByCode();
      // 获取招聘数据
      this.recruitmentList();
    },
    handleCheckAllChange(val) {
      this.checkedCities = val ? this.cityOptions : [];
      this.isIndeterminate = false;
    },
    changeItem(i) {
      this.$set(this.cities[i], 'checked', !this.cities[i].checked); // 改变数组
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.cities.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.cities.length;
      this.recruitmentList();
    },
    getDicItemByCode() {
      let params = { dicCode: 'targetCity' };
      getDicItemByCode(params).then(({ result }) => {
        result.forEach((e) => {
          e.checked = false;
          this.cityOptions.push(e.value);
        });
        this.cities = result;
        this.checkedCities = this.cityOptions;
      });
    },
    recruitmentList() {
      let params = {
        flagAll: this.checkAll,
        // cityCodes: JSON.stringify(this.checkedCities),
        cityCodes: this.checkedCities.join(','),
        // cityCodes: this.checkedCities,
        pageNo: this.pageNo,
        pageSize: 100,
      };
      // this.checkAll && (params.flagAll = true);

      recruitmentList(params).then(({ result }) => {
        result.records.forEach((item) => {
          item.showMore = true;
        });
        this.list = result.records;
      });
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
.join-us {
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
}

.left {
  width: 246px;
}

.city-all {
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.right {
  width: 1080px;
}

.join-item {
  cursor: pointer;
  width: 1080px;
  display: flex;
  justify-content: space-between;
}

.item-content {

  // display: flex;
  padding-bottom: 30px;
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 30px;
}

.con-head {
  width: 890px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.con-title {
  font-weight: 500;
  font-size: 22px;
  line-height: 22px;
}

.con-adderss {
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #333333;
}

.con-text {
  margin-top: 10px;
  width: 890px;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #666666;


  /deep/ {
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    strong,
    div {
      margin: 0 !important;
      padding: 0 !important;
      font-family: 'PingFang SC' !important;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: 30px !important;
      font-size: 16px !important;
      color: #666666 !important;
    }
  }
}

.notShowMore {
  @extend .over-flow3;
  max-height: 90px;
}


.item-sub-btn {
  display: flex;
  justify-content: center;
  // align-items: center;
  margin-top: 20px;
}

.sub-btn {
  width: 154px;
  cursor: pointer;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background: #0c173b;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
}

.join-us /deep/ {
  .el-checkbox-group {
    display: flex;
    flex-direction: column;
  }

  .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #6e59d0;
    border-color: #6e59d0;
  }

  .el-checkbox__input.is-checked+.el-checkbox__label {
    color: #6e59d0;
  }

  .el-checkbox__inner:hover,
  .is-focus>.el-checkbox__inner {
    border-color: #6e59d0 !important;
  }

  .el-dialog {
    padding: 12px;
    margin-top: 30vh !important;
    border-radius: 2px;
  }

  .el-dialog__body {
    padding-top: 8px;
    padding-bottom: 24px;
    font-size: 14px;
    line-height: 22px;
  }

  .el-dialog__header {
    padding-bottom: 0;
    line-height: 0;
    font-weight: 500;
  }

  .el-dialog__footer {
    padding-top: 0;
  }

  .el-button--info {
    background: #0c173b;
    border: 1px solid #1890FF;
  }

}
</style>
